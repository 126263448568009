import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// models
import { Currency } from 'app/connect/models/currency.model';

@Injectable()
export class CurrencyListService {
    baseUrl = 'api/v2/currencies';

    constructor(private http: HttpClient) {}

    getCurrencies$(): Observable<Currency[]> {        
        return this.http.get<Currency[]>(this.baseUrl);
    }
}
