import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// modules
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';

// store
import { effects } from 'app/connect/store/effects';
import { reducers } from 'app/connect/store/reducers';

// components
import { BrandingComponent } from './components/branding/branding.component';
import { BrandPanelComponent } from './components/brand-panel/brand-panel.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CardDisplayComponent } from './components/card-display/card-display.component';
import { CardFooterToggleComponent } from './components/card-footer-toggle/card-footer-toggle.component';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ClientSelectorOptionComponent } from './components/client-selector-option/client-selector-option.component';
import { FilterComponent } from './components/filter/filter.component';
import { FolderCardComponent } from './components/folder-card/folder-card.component';
import { HeaderComponent } from './components/header/header.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { PageComponent } from './components/page/page.component';
import { PoweredByCredasComponent } from './components/powered-by-credas/powered-by-credas.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserGroupSelectorComponent } from './components/user-group-selector/user-group-selector.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { HelpFaqSearchComponent } from './components/help-faq-search/help-faq-search.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MaximizerComponent } from 'app/connect/components/maximizer/maximizer.component';
import { LozengeComponent } from './components/lozenge/lozenge.component';
//import { UserProfileDialogComponent } from 'app/authentication-v2/dialogs';
import { UserNotificationsDialogComponent } from './components/user-notifications-dialog/user-notifications-dialog.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { NotificationPriorityComponent } from './components/notification-priority/notification-priority.component';
import { UserNotificationListComponent } from './components/user-notification-list/user-notification-list.component';
import { UserAlertListComponent } from './components/user-alert-list/user-alert-list.component';
import { UserAlertGroupComponent } from './components/user-alert-group/user-alert-group.component';
import { UserAlertDetailsComponent } from './components/user-alert-details/user-alert-details.component';
import { UserAlertTaskStatusComponent } from './components/user-alert-task-status/user-alert-task-status.component';
import { ChargeableModeWarningDialogComponent } from 'app/connect/components/chargeable-mode-warning-dialog/chargeable-mode-warning-dialog.component';
import { UserProfileDialogComponent } from './components/user-profile-dialog/user-profile-dialog.component';
import { CodeEntryComponent } from './components/code-entry/code-entry.component';
import { AuthenticatorAppSetupComponent } from './components/authenticator-app-setup/authenticator-app-setup.component';

// services
import { ConnectService } from './services/connect.service';
import { HelpFaqService } from './services/help-faq.service';
import { TooltipService } from './services/tooltip.service';
import { NotificationService } from './services/notification.service';
import { UserAlertService } from './services/user-alert.service';
import { PostcodeLookupService } from './services/postcode-lookup.service';
import { CurrencyListService } from './services/currency-list.service';

// models
import { StoreNames } from './models/store-names.model';


const modules = [
    CommonModule,
    SharedModule,
    RouterModule,
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(StoreNames.connectCommon, reducers),
];

const services = [
    ConnectService,
    HelpFaqService,
    TooltipService,
    NotificationService,
    UserAlertService,
    PostcodeLookupService,
    CurrencyListService
];

const components = [
    BrandingComponent,
    BrandPanelComponent,
    BreadcrumbComponent,
    CardDisplayComponent,
    CardFooterToggleComponent,
    ClientSelectorComponent,
    ClientSelectorOptionComponent,
    FilterComponent,
    FolderCardComponent,
    HeaderComponent,
    ImagePopupComponent,
    PageComponent,
    PoweredByCredasComponent,
    ToolbarComponent,
    UserGroupSelectorComponent,
    UserMenuComponent,
    HelpFaqSearchComponent,
    TooltipComponent,
    MaximizerComponent,
    LozengeComponent,
    //UserProfileDialogComponent,
    UserNotificationsDialogComponent,
    NotificationCardComponent,
    NotificationDetailsComponent,
    NotificationPriorityComponent,
    UserAlertListComponent,
    UserNotificationListComponent,
    UserAlertGroupComponent,
    UserAlertDetailsComponent,
    UserAlertTaskStatusComponent,
    ChargeableModeWarningDialogComponent,
    UserProfileDialogComponent,
    CodeEntryComponent,
    AuthenticatorAppSetupComponent
];

@NgModule({
    imports: modules,
    providers: services,
    declarations: components,
    exports: components,
})
export class ConnectModule {}
