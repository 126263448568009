import { Injectable } from '@angular/core';

declare let ga: any;

@Injectable()
export class EventTrackingService {
    constructor() { }

    sendEvent(category: string, action: string, label: string = null, value: number = null): void {
        if (!this.enabled) {
            return;
        }
        ga('send', 'event', category, action, label, value);
    }

    private get enabled(): boolean {
        return typeof ga !== 'undefined';
    }
}