import { Component, Input } from '@angular/core';

@Component({
    templateUrl: 'page-information-text.component.html',
    styleUrls: ['page-information-text.component.scss'],
    selector: 'app-page-information-text'
})
export class PageInformationComponent {
    @Input()
    text: string;
}