import { createAction, props } from '@ngrx/store';

// models
import { Tooltip } from 'app/connect/models/tooltip.model';

// enums
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

// actions
export const GetTooltips = createAction(
    '[Tooltips] Get tooltips',
    props<{page: TooltipPage; isTour?: boolean}>()
);

export const GetTooltipsSuccess = createAction(
    '[Tooltips] Get tooltips success',
    props<{tooltips: Tooltip[]}>()
);

export const GetTooltipsFail = createAction(
    '[Tooltips] Get tooltips fail',
);

export const SetTooltipVisibility = createAction(
    '[Tooltips] Set tooltip visibility',
    props<{id: number; visible: boolean}>()
);

export const CreateTooltipUserRecord = createAction(
    '[Tooltips] Create tooltip user record',
    props<{tooltip: Tooltip}>()
);

export const CreateTooltipUserRecordSuccess = createAction(
    '[Tooltips] Create tooltip user record success',
);

export const CreateTooltipUserRecordFail = createAction(
    '[Tooltips] Create tooltip user record fail',
);

export const GetShowTooltipsTourOnNavigation = createAction(
    '[Tooltips] Create tooltip user record fail',
);

export const GetShowTooltipsTourOnNavigationSuccess = createAction(
    '[Tooltips] Get show on navigation success',
    props<{showTooltipsTourOnNavigation: boolean}>()
);

export const GetShowTooltipsTourOnNavigationFail = createAction(
    '[Tooltips] Get show on navigation fail',
);

export const SetTooltipsIsTour = createAction(
    '[Tooltips] Set tooltips is tour',
    props<{isTour: boolean}>()
);
