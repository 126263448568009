import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[EmptyToNull]' })
export class EmptyToNullDirective {
    constructor(@Self() private ngControl: NgControl) { }

    @HostListener('keyup', ['$event']) onKeyDowns() {
        if (this.ngControl.control.value !== null && this.ngControl.value !== undefined) {
            if (this.ngControl.control.value.toString()?.trim() === '') {
                this.ngControl.reset(null);
            }
        }
    }
}