export class LogoutOptions {
    logActivity: boolean;
    redirectToLoginPage: boolean;
    redirectUrl: string;

    constructor(logActivity: boolean, redirectToLoginPage: boolean, redirectUrl: string) {
        this.logActivity = logActivity;
        this.redirectToLoginPage = redirectToLoginPage;
        this.redirectUrl = redirectUrl;
    }
}
