import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
    transform(array: any, field: string, order: 'asc' | 'desc' = 'asc'): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        const sortedArray = [...array].sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });

        if(order === 'desc') {
            sortedArray.reverse();
        }

        return sortedArray;
    }
}
