import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PagingNavigationPage } from 'app/shared/models/paging-navigation-page.models';
import { PagingNavigationPageType } from 'app/shared/enums/paging-navigation-page-type.enum';

// Store
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRoot from 'app/store';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

@Component({
    selector: 'app-paging-navigation',
    templateUrl: './paging-navigation.component.html',
    styleUrls: ['./paging-navigation.component.scss']
})
export class PagingNavigationComponent extends BaseComponent implements OnInit {

    PagingNavigationPageType = PagingNavigationPageType;

    pages: PagingNavigationPage[] = [new PagingNavigationPage(1, PagingNavigationPageType.Standard)];
    currentPage: PagingNavigationPage = this.pages[0];
    paginationPages: PagingNavigationPage[];
    invalid: boolean = false;

    @Input()
    initialPageCount: number;

    @Input()
    numberOfPagingButtons: number = 7;

    @Input()
    showSaveButton: boolean = false;

    @Input()
    inWizard: boolean = false;

    @Input()
    disabled: boolean = false;

    @Input()
    handleBackAndNext: boolean = false;

    @Output()
    pageChanged: EventEmitter<PagingNavigationPage> = new EventEmitter();

    @Output()
    pagesChanged: EventEmitter<PagingNavigationPage[]> = new EventEmitter();

    @Output()
    saveClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    nextClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    backClicked: EventEmitter<void> = new EventEmitter();

    constructor(
        private rootStore: Store<fromRoot.State>
    ) {
        super();
    }

    ngOnInit(): void {
        // Check if mobile override of paging buttons is required
        if (this.numberOfPagingButtons > 7) {
            this.rootStore.pipe(
                takeUntil(this.ngUnsubscribe),
                select(fromRoot.isMobileDevice))
                .subscribe(isMobileDevice => {
                    if (isMobileDevice) {
                        this.numberOfPagingButtons = 7;
                    }
                });
        }

        this.pageChanged.emit(this.currentPage);

        while (this.pages.length < this.initialPageCount) {
            this.pages.push(new PagingNavigationPage(this.pages.length + 1, PagingNavigationPageType.Standard));
        }

        this.calculateActivePagination();

        this.pagesChanged.emit(this.pages);
    }

    addPage(): void {
        this.pages.push(new PagingNavigationPage(this.pages.length + 1, PagingNavigationPageType.Standard));
        this.setPage(this.pages[this.pages.length - 1]);
        this.pagesChanged.emit(this.pages);
    }

    moveFirst(): void {
        this.setPage(this.pages[0]);
    }

    moveLast(): void {
        this.setPage(this.pages[this.pages.length - 1]);
    }

    moveNext(): void {
        this.setPage(this.pages[this.currentPage.pageNumber]);
    }

    movePrevious(): void {
        this.setPage(this.pages[this.currentPage.pageNumber - 2]);
    }

    moveToPage(pageNumber: number): void {
        if (pageNumber > this.pageCount || pageNumber < 1) {
            return;
        }

        this.setPage(this.pages[pageNumber - 1]);
    }

    get isFirstPage(): boolean {
        return this.currentPage.pageNumber === 1;
    }

    get isLastPage(): boolean {
        return this.currentPage.pageNumber === this.pageCount;
    }

    get pageCount(): number {
        return this.pages.length;
    }

    get firstPage(): PagingNavigationPage {
        return this.pages[0];
    }

    get lastPage(): PagingNavigationPage {
        return this.pages[this.pages.length - 1];
    }

    setPageCount(pageCount: number) {
        this.pages = [new PagingNavigationPage(1, PagingNavigationPageType.Standard)];

        while (this.pages.length < pageCount) {
            this.pages.push(new PagingNavigationPage(this.pages.length + 1, PagingNavigationPageType.Standard));
        }

        if (this.currentPage.pageNumber > this.pages.length || !this.currentPage) {
            this.currentPage = this.pages[0];
            this.pageChanged.emit(this.currentPage);
        }

        this.calculateActivePagination();

        this.pagesChanged.emit(this.pages);
    }

    setPageType(pageNumber: number, type: PagingNavigationPageType): void {
        if (pageNumber < 1 || pageNumber > this.pageCount) {
            return;
        }

        this.pages[pageNumber - 1].type = type;

        for (const page of this.paginationPages) {
            if (page.pageNumber === pageNumber) {
                page.type = type;
            }
        }
    }

    setInvalid(invalid: boolean) {
        this.invalid = invalid;
    }

    calculateActivePagination(): void {
        this.paginationPages = this.pages.filter(p => this.displayPage(p));
    }

    displayPage(page: PagingNavigationPage): boolean {
        const leftHandPages = Math.max(this.numberOfPagingButtons - 6, 0) + Math.max(((this.currentPage.pageNumber - this.pages.length) + 3), 0);
        if (
            // All pages if under count
            this.pages.length <= this.numberOfPagingButtons ||
            // First two pages
            page.pageNumber <= 2 ||
            // Last two pages
            page.pageNumber >= this.pages.length - 1 ||
            // Current page
            page.pageNumber === this.currentPage.pageNumber ||
            // X the left of current page
            page.pageNumber >= this.currentPage.pageNumber - leftHandPages && page.pageNumber < this.currentPage.pageNumber ||
            // One to the right of current page
            page.pageNumber === this.currentPage.pageNumber + 1 ||
            // scope for left ellipsis not showing (current number within the first x: first....first+1....current-1....current)
            this.currentPage.pageNumber <= this.numberOfPagingButtons - 3 && page.pageNumber <= this.numberOfPagingButtons - 2 ||
            // scope for right ellipsis not showing (current number within the last 4: current....current+1....last-1....last)
            this.currentPage.pageNumber > this.pages.length - 4 && page.pageNumber >= this.pages.length - 4
        ) {
            return true;
        }

        return false;
    }

    displayEllipsis(page: PagingNavigationPage): boolean {
        if (this.pages.length <= this.numberOfPagingButtons || (page.pageNumber !== 2 && page.pageNumber !== this.pages.length - 1)) {
            return false;
        }

        if (page.pageNumber === 2 && this.currentPage.pageNumber > 4) {
            return true;
        }

        if (page.pageNumber === this.pages.length - 1 && this.currentPage.pageNumber + 3 < this.pages.length) {
            return true;
        }

        return false;
    }

    setPage(page: PagingNavigationPage): void {
        this.currentPage = page;
        this.calculateActivePagination();
        this.pageChanged.emit(this.currentPage);
    }

    setPageToIndex(pageIndex: number): void {
        this.currentPage = this.pages[pageIndex];
        this.calculateActivePagination();
    }

    save(): void {
        this.saveClicked.emit();
    }

    back(): void {
        if (this.handleBackAndNext) {
            this.backClicked.emit();
        } else {
            this.movePrevious();
        }
    }

    next(): void {
        if (this.handleBackAndNext) {
            this.nextClicked.emit();
        } else {
            this.moveNext();
        }
    }
}