// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/tooltip.actions';

// models
import { Tooltip } from 'app/connect/models/tooltip.model';

// enums
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

export interface TooltipState {
    tooltips: Tooltip[];
    showTooltipsTourOnNavigation: boolean;
    isTour: boolean;
    page: TooltipPage;
    loaded: boolean;
}

const initialState: TooltipState = {
    tooltips: null,
    showTooltipsTourOnNavigation: false,
    isTour: false,
    page: null,
    loaded: false
};

const tooltipReducer = createReducer(
    initialState,
    on(actions.GetTooltips, (state, action) => ({
        ...state,
        tooltips: null,
        page: action.page,
        loaded: false
    })),
    on(actions.GetTooltipsSuccess, (state, action) => ({
        ...state,
        tooltips: action.tooltips,
        loaded: true
    })),
    on(actions.SetTooltipVisibility, (state, action) => ({
        ...state,
        tooltips: state.tooltips?.map(t => ({
            ...t,
            visible: t.id === action.id ? action.visible : t.visible
        })),
        loaded: false
    })),
    on(actions.GetShowTooltipsTourOnNavigationSuccess, (state, action) => ({
        ...state,
        showTooltipsTourOnNavigation: action.showTooltipsTourOnNavigation,
    })),
    on(actions.SetTooltipsIsTour, (state, action) => ({
        ...state,
        isTour: action.isTour
    })),
);

export function reducer(state: TooltipState, action: Action) {
    return tooltipReducer(state, action);
}
