import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum TooltipPortal {
    EntityPortal = 1,
    ClientPortal = 2
}

export const TooltipPortals = [
    new EnumValueTitle(TooltipPortal.EntityPortal, 'Entity Portal'),
    new EnumValueTitle(TooltipPortal.ClientPortal, 'Client Portal'),
];

export function getTooltipPortalName(tooltipPortal: TooltipPortal): string {
    return TooltipPortals.find(d => d.value === tooltipPortal)?.title;
}