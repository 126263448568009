import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-card-footer-toggle',
    templateUrl: './card-footer-toggle.component.html',
    styleUrls: ['./card-footer-toggle.component.scss']
})
export class CardFooterToggleComponent {

    @Input()
    toggleTrue: boolean;

    @Input()
    trueText: string;

    @Input()
    falseText: string;

    @Output()
    toggled = new EventEmitter<boolean>();

    handleToggleClicked(): void {
        this.toggleTrue = !this.toggleTrue;
        this.toggled.emit(this.toggleTrue);
    }
}