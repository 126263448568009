import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExternalReferencesService {
    private baseEndpoint = `externalreferences`;

    constructor(private http: HttpClient) { }

    checkCredasReference(organisationId: string): Observable<boolean> {
        const url = `${this.baseEndpoint}/checkCredas/${organisationId}`;
        return this.http.get<boolean>(url);
    }
}