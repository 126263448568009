import { Injectable } from '@angular/core';
import { compile, helpers } from 'handlebars/dist/cjs/handlebars';

export interface HandlebarTokens {
    [key: string]: any;
}

type HandlebarsTemplate = (tokens: HandlebarTokens) => string;

function compileTemplate(template: string) {
    return compile(template);
}

function renderTemplate(template: string, tokens?: HandlebarTokens) {
    const compiled = compileTemplate(template);

    tokens = tokens || {};

    return compiled(tokens);
}

export function isTemplateValid(template: string): boolean {
    try {
        renderTemplate(template);
    } catch (ex) {
        return false;
    }

    return true;
}

@Injectable({ providedIn: 'root' })
export class HandlebarsService {
    private static Initialised = false;

    constructor() {
        if (!HandlebarsService.Initialised) {
            this.initialiseHandlebars();
            HandlebarsService.Initialised = true;
        }
    }

    compileTemplate(template: string): HandlebarsTemplate {
        return compileTemplate(template);
    }

    renderTemplate(template: string, tokens?: HandlebarTokens) {
        return renderTemplate(template, tokens);
    }

    isTemplateValid(template: string): boolean {
        return isTemplateValid(template);
    }

    private initialiseHandlebars() {
        helpers.and = function() {
            /* eslint-disable prefer-rest-params */
            const args = Array.prototype.slice.call(arguments);
            /* eslint-enable prefer-rest-params */
            const options = args[args.length - 1];

            for (let i = 0; i < args.length - 1; i++) {
                if (!args[i]) {
                    return options.inverse(this);
                }
            }

            return options.fn(this);
        };
    }
}