import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

// rxjs
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// models
import { SearchableOption } from 'app/shared/models/searchable-option.model';

@Component({
    selector: 'app-searchable-multi-select-input',
    templateUrl: './searchable-multi-select-input.component.html',
    styleUrls: ['./searchable-multi-select-input.component.scss'],
})
export class SearchableMultiSelectInputComponent implements OnInit {

    @Input()
    options: SearchableOption[];

    @Input()
    label: string = null;

    @Input()
    readonly: boolean = false;

    @Input()
    showSelectedOptions: boolean = true;

    @Input()
    returnMultipleSelectedOptions: boolean = false;

    @Output()
    optionSelected = new EventEmitter<SearchableOption | SearchableOption[]>();

    @Output()
    optionRemoved = new EventEmitter<SearchableOption | SearchableOption[]>();

    searchControl: FormControl;

    filteredOptions: Observable<SearchableOption[]>;
    selectedOptions: SearchableOption[] = [];

    ngOnInit(): void {
        this.searchControl = new FormControl();
        this.selectedOptions = this.options.filter(x => x.selected);

        if (this.readonly) {
            this.searchControl.disable();
        } else {
            this.filteredOptions = this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterOptions(value))
            );
        }
    }

    filterOptions(value: string): SearchableOption[] {
        if (value && typeof value === 'string' && this.options) {
            const filterValue = value.toLowerCase();
            return this.options.filter(option => option.value.toLowerCase().includes(filterValue));
        } else {
            return this.options;
        }
    }

    getDisplay(option: SearchableOption): string {
        return option?.value ?? '';
    }

    onMultiOptionSelected(event: Event, selectedOption: SearchableOption) {
        event.stopPropagation();
        this.toggleSelection(selectedOption);
    }

    onMultiOptionRemoved(option: SearchableOption) {
        option.selected = false;
        const i = this.selectedOptions.findIndex(sv => sv.value === option.value);
        this.selectedOptions.splice(i, 1);

        if (this.returnMultipleSelectedOptions) {
            this.optionRemoved.emit(this.selectedOptions);
        } else {
            this.optionRemoved.emit(option);
        }
    }

    toggleSelection(option: SearchableOption) {
        if (!option.selected) {
            option.selected = true;
            this.selectedOptions.push(option);

            if (this.returnMultipleSelectedOptions) {
                this.optionSelected.emit(this.selectedOptions);
            } else {
                this.optionSelected.emit(option);
            }
        } else {
            this.onMultiOptionRemoved(option);
        }
    }
}