// ngrx
import { ActionReducerMap } from '@ngrx/store';

// store
import * as fromApplication from './application.reducer';
import * as fromBranding from './branding.reducer';
import * as fromBreadcrumb from './breadcrumb.reducer';
import * as fromUserInterface from './user-interface.reducer';
import * as fromHelpFaq from './help-faq.reducer';
import * as fromTooltip from './tooltip.reducer';
import * as fromSignalr from './signalr.reducer';
import * as fromNotification from './notification.reducer';
import * as fromUserAlert from './user-alert.reducer';
import * as fromPostcodeLookup from './postcode-lookup.reducer';
import * as fromCurrencyList from './currency-list.reducer';

export interface ConnectStoreState {
    application: fromApplication.ApplicationState;
    breadcrumbs: fromBreadcrumb.BreadcrumbState;
    branding: fromBranding.BrandingState;
    userInterface: fromUserInterface.UserInterfaceState;
    helpFaq: fromHelpFaq.HelpFaqState;
    tooltip: fromTooltip.TooltipState;
    signalr: fromSignalr.SignalRState;
    notification: fromNotification.NotificationState;
    userAlert: fromUserAlert.UserAlertState;
    postcodeLookup: fromPostcodeLookup.PostcodeLookupState;
    currencies: fromCurrencyList.CurrencyListState;
}

export const reducers: ActionReducerMap<ConnectStoreState> = {
    application: fromApplication.reducer,
    branding: fromBranding.reducer,
    breadcrumbs: fromBreadcrumb.reducer,
    userInterface: fromUserInterface.reducer,
    helpFaq: fromHelpFaq.reducer,
    tooltip: fromTooltip.reducer,
    signalr: fromSignalr.reducer,
    notification: fromNotification.reducer,
    userAlert: fromUserAlert.reducer,
    postcodeLookup: fromPostcodeLookup.reducer,
    currencies: fromCurrencyList.reducer,
};

export { ApplicationState } from './application.reducer';
export { BrandingState } from './branding.reducer';
export { BreadcrumbState } from './breadcrumb.reducer';
export { UserInterfaceState } from './user-interface.reducer';
export { HelpFaqState } from './help-faq.reducer';
export { TooltipState } from './tooltip.reducer';
export { SignalRState } from './signalr.reducer';
export { NotificationState } from './notification.reducer';
export { UserAlertState as AlertState } from './user-alert.reducer';
export { PostcodeLookupState } from './postcode-lookup.reducer';
export { CurrencyListState } from './currency-list.reducer';
