<div>
    <div class="font-question-label" *ngIf="label">{{label}}</div>
    <mat-form-field appearance="outline">
        <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplay" (optionSelected)="onOptionSelected($event.option.value)">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.value}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>