// models
import { FilterConfiguration } from './filter-configuration.model';
import { FilterOptionDate } from './filter-option-date.model';
import { FilterOptionSelection } from './filter-option-selection.model';

// utilities
import {toDatefromIsoDatePartString } from 'app/shared/utilities/date-utilities';

export class Filter {
    filterOptions:  FilterOptionSelection[];
    filterDateOptions: FilterOptionDate[];

    searchText: string; // CONNECT REMOVE once all pages moved over

    constructor(public configuration: FilterConfiguration) {
        if (configuration && configuration.options) {
            this.filterOptions = configuration.options.map(o => new FilterOptionSelection(o));
            this.filterDateOptions = configuration.dateOptions;
        }
    }

    getFilterOptionId(name: string): string {
        const filterOption = this.getFilterOption(name);

        if (!filterOption) {
            return null;
        }

        if (filterOption.selectedIds.length === 0) {
            return null;
        }

        return filterOption.selectedIds[0];
    }

    getFilterOptionIds(name: string): string[] {
        const filterOption = this.getFilterOption(name);

        if (!filterOption) {
            return null;
        }

        return filterOption.selectedIds;
    }

    getFilterOptionDateValue(name: string): Date {
        const filterDateOption = this.filterDateOptions.filter(f => f.name === name)[0];

        if (!filterDateOption) {
            return null;
        }
        return filterDateOption.value;
    }

    setFilterOptionDateValue(name: string, dateStringValue: string) {
        const filterDateOption = this.filterDateOptions.filter(f => f.name === name)[0];

        if (!filterDateOption) {
            return null;
        }
        filterDateOption.setValue(toDatefromIsoDatePartString(dateStringValue));
    }

    clearFilterOptionIds(name: string = null): void {
        if (name) {
            const filterOption = this.getFilterOption(name);

            if (!filterOption) {
                return;
            }

            filterOption.clear();
        } else {
            for (const o of this.filterOptions) {
                o.clear();
            }
        }
    }

    setFilterOptionId(name: string, id: string): void {
        const filterOption = this.getFilterOption(name);

        if (!filterOption) {
            return;
        }

        filterOption.selectValue(id);
    }

    clear(): void {
        this.searchText = null;
        for (const filterOption of this.filterOptions) {
            filterOption.clear();
        }

        for (const filterDateOption of this.filterDateOptions) {
            filterDateOption.clear();
        }
    }

    getFilterOption(name: string): FilterOptionSelection {
        return this.filterOptions.find(f => f.name === name);
    }
}