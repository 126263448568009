import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    map,
    switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

// actions
import * as CurrencyListActions from 'app/connect/store/actions/currency-list.actions';

// services
import { CurrencyListService } from 'app/connect/services/currency-list.service';

@Injectable()
export class CurrencyListEffects {
    constructor(
        private actions$: Actions,
        private currencyListService: CurrencyListService        
    ) {}

    getCurrencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurrencyListActions.GetCurrencies),
            switchMap(() =>
                this.currencyListService
                    .getCurrencies$()
                    .pipe(
                        map((currencies) =>
                            CurrencyListActions.GetCurrenciesSuccess({
                                currencies,
                            })
                        ),
                        catchError(() =>
                            of(CurrencyListActions.GetCurrenciesFailure())
                        )
                    )
            )
        )
    );
}
