import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CameraFacingMode } from 'app/shared/enums/camera-facing-mode.enum';

@Injectable()
export class VideoCaptureService {

    private frontMediaStreamConstraints: MediaStreamConstraints = {};
    private backMediaStreamConstraints: MediaStreamConstraints = {};
    private emptyDeviceInfo: MediaDeviceInfo[] = [];

    getAvailableVideoDevices(): Observable<MediaDeviceInfo[]> {
        return new Observable<MediaDeviceInfo[]>(observer => {
            navigator.mediaDevices.getUserMedia(this.getConstraints(CameraFacingMode.Any))
            .then((stream) => {
                this.stopStream(stream);
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devices: MediaDeviceInfo[]) => {
                observer.next(devices.filter(d => d.kind === 'videoinput'));
                observer.complete();
            })
            .catch(() => {
                observer.next(this.emptyDeviceInfo);
                observer.complete();
            });
        });
    }

    getConstraints(defaultFacingMode: CameraFacingMode): MediaStreamConstraints {

        this.setConstraints();

        if (navigator.mediaDevices.getSupportedConstraints().facingMode) {
            switch (defaultFacingMode) {
                case CameraFacingMode.Front : {
                    return this.frontMediaStreamConstraints;
                }
                case CameraFacingMode.Back : {
                    return this.backMediaStreamConstraints;
                }
                case CameraFacingMode.Any : {
                    return this.frontMediaStreamConstraints;
                }
            }
        } else {
            return this.frontMediaStreamConstraints;
        }
    }

    setConstraints(): void {
        this.frontMediaStreamConstraints = {
            video: true
        };

        this.backMediaStreamConstraints = {
            video: true
        };
    }

    getStream(constraints: MediaStreamConstraints): Observable<MediaStream> {
        return new Observable<MediaStream>(observer => {
            navigator.mediaDevices.getUserMedia(constraints)
            .then((stream: MediaStream) => {
                observer.next(stream);
                observer.complete();
            })
            .catch(() => {
                observer.error('Unable to connect to webcam');
            });
        });
    }

    startWebcam(defaultFacingMode: CameraFacingMode): Observable<MediaStream> {
       return this.getStream(this.getConstraints(defaultFacingMode));
    }

    startSpecifiedWebcam(cameraId: string): Observable<MediaStream> {
        return this.getStream( { video: { deviceId: cameraId} });
    }

    stopWebcam(video: HTMLVideoElement, videoStream: MediaStream): void {
        video.pause();
        video.src = '';
        videoStream.getTracks()[0].stop();
    }

    stopStream(videoStream: MediaStream): void {
        videoStream.getTracks()[0].stop();
    }

    getImageFromVideo(video: HTMLVideoElement, canvas: HTMLCanvasElement): string {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);
        return canvas.toDataURL('image/jpeg');
    }
}