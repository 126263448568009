// ngrx
import { createSelector, createFeatureSelector } from '@ngrx/store';

// store
import * as fromFeature from 'app/connect/store/reducers';
import { TooltipState } from 'app/connect/store/reducers/tooltip.reducer';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

// States
const getStoreState = createFeatureSelector<fromFeature.ConnectStoreState>(StoreNames.connectCommon);
const getReducerState = createSelector(getStoreState, (state: fromFeature.ConnectStoreState) => state.tooltip);

// Selectors
export const getTooltips = createSelector(getReducerState, (state: TooltipState) => state.tooltips);
export const getTooltipsIsTour = createSelector(getReducerState, (state: TooltipState) => state.isTour);
export const getShowTooltipsTourOnNavigation = createSelector(getReducerState, (state: TooltipState) => state.showTooltipsTourOnNavigation);
export const getTooltipsPage = createSelector(getReducerState, (state: TooltipState) => state.page);
export const getTooltipsLoaded = createSelector(getReducerState, (state: TooltipState) => state.loaded);
