import { PagingNavigationPageType } from 'app/shared/enums/paging-navigation-page-type.enum';

export class PagingNavigationPage {
    pageNumber: number;
    type: PagingNavigationPageType;

    constructor(pageNumber: number, type: PagingNavigationPageType) {
        this.pageNumber = pageNumber;
        this.type = type;
    }
}