import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        this.onDestroy();
    }

    /// Override this method in child component to invoke actions on destroy.
    protected onDestroy(): void { }
}
