import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';

// ngrx | rxjs
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { ConnectService } from 'app/connect/services/connect.service';

@Injectable()
export class ShortUrlGuard implements CanActivate {

    constructor(
        private router: Router,
        private connectService: ConnectService) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const shortCode = route.params.link;
        if (!shortCode) {
            return this.invalidLink();
        }
        this.connectService.convertShortUrl$(shortCode)
            .pipe(take(1))
            .subscribe((url: string) => {
                if (!url) {
                    return this.invalidLink();
                } else {
                    window.location.href = url;
                    return of(true);
                }
            },
           () => this.invalidLink());
    }

    private invalidLink(): Observable<boolean> {
        this.router.navigate(['/not-found']);
        return of(false);
    }
}

