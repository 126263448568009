import { Injectable } from '@angular/core';
import { EventTrackingService } from 'app/shared/services/event-tracking.service';

@Injectable()
export class AuthenticationEventTrackingService {

    constructor(private eventTrackingService: EventTrackingService) { }

    loginClicked() {
        this.sendAuthenticationEvent('Login Clicked');
    }

    loginFailed() {
        this.sendAuthenticationEvent('Login Failed');
    }

    registerClicked() {
        this.sendAuthenticationEvent('Register Clicked');
    }

    forgotPasswordClicked() {
        this.sendAuthenticationEvent('Forgot Password Clicked');
    }

    sendResetPasswordClicked() {
        this.sendAuthenticationEvent('Send Reset Password Clicked');
    }

    redirectingExistingUser() {
        this.sendAuthenticationEvent('Redirecting Existing User For Register Page');
    }

    softwareExpired() {
        this.sendAuthenticationEvent('Software Version Expired');
    }

    private sendAuthenticationEvent(action: string) {
        this.eventTrackingService.sendEvent('Authentication', action);
    }
}