// ngrx
import { createReducer, on, Action } from '@ngrx/store';

// store
import * as actions from 'app/connect/store/actions/help-faq.actions';

// models
import { HelpFaq } from 'app/connect/models/help-faq.model';
import { HelpFaqSummary } from 'app/connect/models/help-faq-summary.model';

export interface HelpFaqState {
    helpFaqs: HelpFaqSummary[];
    helpFaq: HelpFaq;
    showHelpFaqsOnNavigation: boolean;
    loading: boolean;
}

const initialState: HelpFaqState = {
    helpFaqs: null,
    helpFaq: null,
    showHelpFaqsOnNavigation: false,
    loading: false
};

const applicationReducer = createReducer(
    initialState,
    on(actions.SearchHelpFaqs, (state) => ({
        ...state,
        loading: true
    })),
    on(actions.SearchHelpFaqsSuccess, (state, action) => ({
        ...state,
        helpFaqs: action.helpFaqs,
        loading: false
    })),
    on(actions.SearchHelpFaqsFail, (state) => ({
        ...state,
        loading: false
    })),
    on(actions.ResetHelpFaqSearchItems, (state) => ({
        ...state,
        loading: false,
        helpFaqs: null
    })),
    on(actions.GetHelpFaqs, (state) => ({
        ...state,
        loading: true
    })),
    on(actions.GetHelpFaqsSuccess, (state, action) => ({
        ...state,
        helpFaqs: action.helpFaqs,
        loading: false
    })),
    on(actions.GetHelpFaqsFail, (state) => ({
        ...state,
        loading: false
    })),
    on(actions.GetHelpFaq, (state) => ({
        ...state,
        loading: true
    })),
    on(actions.GetHelpFaqSuccess, (state, action) => ({
        ...state,
        helpFaq: action.helpFaq,
        loading: false
    })),
    on(actions.GetHelpFaqFail, (state) => ({
        ...state,
        loading: false
    })),
    on(actions.GetShowHelpFaqsOnNavigation, (state) => ({
        ...state,
        loading: true
    })),
    on(actions.GetShowHelpFaqsOnNavigationSuccess, (state, action) => ({
        ...state,
        showHelpFaqsOnNavigation: action.showHelpFaqsOnNavigation,
        loading: false
    })),
    on(actions.GetShowHelpFaqsOnNavigationFail, (state) => ({
        ...state,
        loading: false
    })),
);

export function reducer(state: HelpFaqState | undefined, action: Action) {
    return applicationReducer(state, action);
}
