<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">404</div>

        <div class="message">Sorry but we could not find the page you are looking for.</div>

    </div>

</div>