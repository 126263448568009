export class RefreshTokenRequest {
    userId; string;
    refreshToken: string;
    accessToken: string;

    constructor(userId: string, refreshToken: string, accessToken: string) {
        this.userId = userId;
        this.refreshToken = refreshToken;
        this.accessToken = accessToken;
    }
}