import { MultiOptionValue } from 'app/shared/models/multi-option-value.model';

export enum ItemType {
    None = 0,
    ScoreRange = 1,
    YesPartialNo = 2,
    TextOnly = 3,
    LowMediumHigh = 4,
    NetPromoterScore = 5,
    NoPartialYes = 6,
    NoYes = 7,
    Date = 8,
    Document = 9,
    ShortText = 10,
    MultiOptionSingle = 11,
    MultiOptionList = 12,
    Separator = 13,
    ESignDocument = 14,
    ESignRichText = 15,
    AdviceText = 16,
    DocumentList = 17,
    Image = 18,
    ImageList = 19,
    ShortTextList = 20,
    PassFailUnderReview = 21,
    InsuranceDate = 22,
    CredasRightToWorkCheck = 23,
    Postcode = 24,
    Number = 25,
    Selfie = 26,
    UniqueActionProcess = 27,
    IdDocuments = 28,
    RichText = 29,
    CourseSelector = 30,
    IdentityVerification = 31,
    LookupList = 32,
    LookupListMultiSelect = 33,
    ESignature = 34
}

export function itemTypeName(itemType: ItemType): string {
    switch (itemType) {
        case ItemType.Date: return 'Date';
        case ItemType.Document: return 'Document';
        case ItemType.LowMediumHigh: return 'Low/Medium/High';
        case ItemType.NetPromoterScore: return 'Net Promoter Score';
        case ItemType.NoPartialYes: return 'No/Partial/Yes';
        case ItemType.NoYes: return 'Yes/No';
        case ItemType.ScoreRange: return 'Score Range';
        case ItemType.ShortText: return 'Short Text';
        case ItemType.TextOnly: return 'Text';
        case ItemType.YesPartialNo: return 'Yes/Partial/No';
        case ItemType.MultiOptionSingle: return 'Multi Option Single';
        case ItemType.MultiOptionList: return 'Multi Option List';
        case ItemType.ESignDocument: return 'eSign Document';
        case ItemType.ESignRichText: return 'eSign Rich Text';
        case ItemType.Separator: return 'Separator';
        case ItemType.AdviceText: return 'Advice Text';
        case ItemType.DocumentList: return 'Document List';
        case ItemType.Image: return 'Image';
        case ItemType.ImageList: return 'Image List';
        case ItemType.ShortTextList: return 'Short Text List';
        case ItemType.PassFailUnderReview: return 'Pass/Fail/Under Review';
        case ItemType.InsuranceDate: return 'Date (Insurance)';
        case ItemType.CredasRightToWorkCheck: return 'Right To Work Check';
        case ItemType.Postcode: return 'Address Lookup';
        case ItemType.Number: return 'Number';
        case ItemType.Selfie: return 'Selfie';
        case ItemType.UniqueActionProcess: return 'Unique Action Process (UAP)';
        case ItemType.IdDocuments: return 'ID Documents';
        case ItemType.RichText: return 'Rich Text';
        case ItemType.CourseSelector: return 'Course Selector';
        case ItemType.IdentityVerification: return 'Identity Verification';
        case ItemType.LookupList: return 'Lookup List';
        case ItemType.LookupListMultiSelect: return 'Lookup List Multi Select';
        case ItemType.ESignature: return 'ESignature';
        default:
            return '';
    }
}

export function isMultiOption(itemType: ItemType): boolean {
    switch (itemType) {
        case ItemType.MultiOptionSingle:
        case ItemType.MultiOptionList:
        case ItemType.LowMediumHigh:
        case ItemType.NoPartialYes:
        case ItemType.NoYes:
        case ItemType.ScoreRange:
        case ItemType.YesPartialNo:
        case ItemType.PassFailUnderReview:
            return true;
        default:
            return false;
    }
}

export function isMultiOptionSingle(itemType: ItemType): boolean {
    switch (itemType) {
        case ItemType.MultiOptionSingle:
        case ItemType.LowMediumHigh:
        case ItemType.NoPartialYes:
        case ItemType.NoYes:
        case ItemType.ScoreRange:
        case ItemType.YesPartialNo:
            return true;
        default:
            return false;
    }
}

export function isLookup(itemType: ItemType): boolean {
    switch (itemType) {
        case ItemType.LookupList:
        case ItemType.LookupListMultiSelect:
            return true;
        default:
            return false;
    }
}

export function isAvailableForConditional(itemType: ItemType): boolean {
    switch (itemType) {
        case ItemType.LookupList:
        case ItemType.ShortText:
        case ItemType.MultiOptionSingle:
        case ItemType.LowMediumHigh:
        case ItemType.NoPartialYes:
        case ItemType.NoYes:
        case ItemType.ScoreRange:
        case ItemType.YesPartialNo:
            return true;
        default:
            return false;
    }
}

export function getDefaultOptions(itemType: ItemType): MultiOptionValue[] {
    switch (itemType) {
        case ItemType.LowMediumHigh:
            return [
                new MultiOptionValue('Low', 0, 1, 'L'),
                new MultiOptionValue('Medium', 50, 2, 'M'),
                new MultiOptionValue('High', 100, 3, 'H'),
            ];
        case ItemType.NoPartialYes:
            return [
                new MultiOptionValue('No', 0, 1, 'N'),
                new MultiOptionValue('Partial', 50, 2, 'P'),
                new MultiOptionValue('Yes', 100, 3, 'Y'),
            ];
        case ItemType.NoYes:
            return [
                new MultiOptionValue('Yes', 100, 1, 'Y'),
                new MultiOptionValue('No', 0, 2, 'N')
            ];
        case ItemType.ScoreRange:
            return [
                new MultiOptionValue('1', 20, 1, '1'),
                new MultiOptionValue('2', 40, 2, '2'),
                new MultiOptionValue('3', 60, 3, '3'),
                new MultiOptionValue('4', 80, 4, '4'),
                new MultiOptionValue('5', 100, 5, '5'),
            ];
        case ItemType.YesPartialNo:
            return [
                new MultiOptionValue('Yes', 0, 1, 'Y'),
                new MultiOptionValue('Partial', 50, 2, 'P'),
                new MultiOptionValue('No', 100, 3, 'N'),
            ];
        case ItemType.PassFailUnderReview:
            return [
                new MultiOptionValue('Pass', 100, 1, 'P'),
                new MultiOptionValue('Under Review', 50, 2, 'R'),
                new MultiOptionValue('Fail', 0, 3, 'F'),
            ];
        default:
            return [];
    }
}

// todo this notion here and in other enums, needs to be replaced with pipes going forward.
export function isEditable(type: ItemType): boolean {
    switch (type) {
        case ItemType.Separator:
        case ItemType.AdviceText: return false;
        default: return true;
    }
}

export function isDateType(type: ItemType): boolean {
    switch (type) {
        case ItemType.Date:
        case ItemType.InsuranceDate: return true;
        default: return false;
    }
}

export function isImageType(type: ItemType): boolean {
    switch (type) {
        case ItemType.Selfie:
        case ItemType.UniqueActionProcess:
        case ItemType.Image:
        case ItemType.ImageList: return true;
        default: return false;
    }
}

export function isImmediateSaveItem(type: ItemType): boolean {
    switch (type) {
        case ItemType.Selfie:
        case ItemType.UniqueActionProcess:
        case ItemType.IdDocuments:
        case ItemType.ESignDocument:
        case ItemType.ESignRichText:
            return true;
        default: return false;
    }
}

export function isList(type: ItemType): boolean {
    switch (type) {
        case ItemType.DocumentList:
        case ItemType.ImageList:
        case ItemType.MultiOptionList:
        case ItemType.ShortTextList:
            return true;
        default: return false;
    }
}

export function isShortText(type: ItemType): boolean {
    switch (type) {
        case ItemType.ShortText:
        case ItemType.ShortTextList:
            return true;
        default: return false;
    }
}

export function isImage(type: ItemType): boolean {
    switch (type) {
        case ItemType.Image:
        case ItemType.ImageList:
            return true;
        default: return false;
    }
}

export function isDocument(type: ItemType): boolean {
    switch (type) {
        case ItemType.Document:
        case ItemType.DocumentList:
            return true;
        default: return false;
    }
}

export function isDate(type: ItemType): boolean {
    switch (type) {
        case ItemType.Date:
        case ItemType.InsuranceDate:
            return true;
        default: return false;
    }
}