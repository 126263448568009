import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-true-false-tick-icon',
    templateUrl: './true-false-tick-icon.component.html',
    styleUrls: ['./true-false-tick-icon.component.scss']
})

export class TrueFalseTickIconComponent {

    @Input()
    public isTrueIf = false;

    @Input()
    public showNullOption = false;

    @Input()
    public trueTooltip: string;

    @Input()
    public falseTooltip: string;
}
