// store
import * as actions from 'app/connect/store/actions/breadcrumb.actions';

// models
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { createReducer, on, Action } from '@ngrx/store';

export interface BreadcrumbState {
    breadcrumbs: Breadcrumb[];
}

const initialState: BreadcrumbState = {
    breadcrumbs: null
};

const breadcrumbReducer = createReducer(
    initialState,
    on(actions.SetBreadcrumbsSuccess, (state, action) => ({
        ...state,
        breadcrumbs: action.breadcrumbs
    })),
    on(actions.ClearBreadcrumbs, (state) => ({
        ...state,
        breadcrumbs: null
    }))
);

export function reducer(state: BreadcrumbState | undefined, action: Action) {
    return breadcrumbReducer(state, action);
}