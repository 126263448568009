export enum YesNoOptions {
    No = 0,
    Yes = 1
}

export function getYeNoOptionsLabel(value: YesNoOptions): string {
    switch (value) {
        case YesNoOptions.No:
            return 'No';
        case YesNoOptions.Yes:
            return 'Yes';
        default:
            return '';
    }
}
