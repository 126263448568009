import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';
import { ModuleService } from 'app/shared/services/module.service';

// enums
import { Modules } from 'app/shared/enums';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable()
export class BreadcrumbEffects {

    setBreadcrumbs$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SetBreadcrumbs),
        switchMap(action => {
            let serviceId: Modules;
            const breadcrumbs = action.breadcrumbs.map(b => {
                if (b.moduleId) {
                    serviceId = b.moduleId;
                }

                if (!b.title && b.moduleId) {
                    return {
                        ...b,
                        title: ModuleService.getTitle(b.moduleId),
                        url: ModuleService.getUrl(b.moduleId)
                    };
                }

                return b;
            });

            const actions: TypedAction<string>[] = [fromConnect.SetBreadcrumbsSuccess({ breadcrumbs })];

            if (serviceId) {
                actions.push(fromConnect.SelectService({serviceId, performRedirect: false }));
            }

            return actions;
        })
    ));

    constructor(private actions$: Actions) { }
}