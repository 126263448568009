import { Injectable } from '@angular/core';

// ngrx / rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

// Store
import * as fromConnect from 'app/connect/store';

// Services
import { TooltipService } from 'app/connect/services/tooltip.service';
import { AlertService } from 'app/shared/components/alert/services/alert.service';

@Injectable()
export class TooltipsEffects {
    getTooltips$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetTooltips),
        switchMap((action) => this.tooltipService.getTooltips$(action.page, action.isTour).pipe(
                map(tooltips => fromConnect.GetTooltipsSuccess({ tooltips })),
                catchError(() => of(fromConnect.GetTooltipsFail()))
            ))
    ));

    createTooltipUserRecord$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.CreateTooltipUserRecord),
        switchMap((action) => this.tooltipService.createTooltipUserRecord$(action.tooltip).pipe(
                map(() => fromConnect.CreateTooltipUserRecordSuccess()),
                catchError(() => of(fromConnect.CreateTooltipUserRecordFail()))
            ))
    ));

    getShowTooltipsTourOnNavigation$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetShowTooltipsTourOnNavigation),
        switchMap(() => this.tooltipService.getShowTooltipsTourOnNavigation$().pipe(
                map(showTooltipsTourOnNavigation => fromConnect.GetShowTooltipsTourOnNavigationSuccess({ showTooltipsTourOnNavigation })),
                catchError(() => of(fromConnect.GetShowTooltipsTourOnNavigationFail()))
            ))
    ));

    fail$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetTooltipsFail || fromConnect.CreateTooltipUserRecordFail || fromConnect.GetShowTooltipsTourOnNavigationFail),
        tap(() => this.alertService.error('An error occurred. Please contact support'))
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private tooltipService: TooltipService,
        private alertService: AlertService
    ) { }
}
