// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { Breadcrumb } from 'app/models/breadcrumb.model';

export const SetBreadcrumbs = createAction(
    '[Connect Breadcrumb] Set breadcrumbs',
    props<{breadcrumbs: Breadcrumb[]}>()
);

export const SetBreadcrumbsSuccess = createAction(
    '[Connect Breadcrumb] Set breadcrumbs success',
    props<{breadcrumbs: Breadcrumb[]}>()
);

export const ClearBreadcrumbs = createAction(
    '[Connect Breadcrumb] Clear breadcrumbs'
);
