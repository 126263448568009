import { toIsoDatePartString } from 'app/shared/utilities/date-utilities';

export class FilterOptionDate {
    name: string;
    id: string;
    minDate: Date;
    maxDate: Date;
    value: Date;
    stringValue: string;

    constructor(name: string, minDate: Date, maxDate: Date) {
        this.name = name;
        this.stringValue = null;
        this.value = null;
        this.minDate = minDate;
        this.maxDate = maxDate;
    }

    setValue(date: Date) {
        if (date) {
            this.value = date;
            this.stringValue = toIsoDatePartString(this.value);
        } else {
            this.value = null;
            this.stringValue = null;
        }
    }

    clear() {
        this.value = null;
        this.stringValue = null;
    }
}