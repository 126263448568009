export class FilterOptionValue {
    id: string;
    optionName: string;
    text: string;

    constructor(optionName: string, id: string, text: string) {
        this.optionName = optionName;
        this.id = id;
        this.text = text;
    }
}