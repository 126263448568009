import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { HelpFaq } from 'app/connect/models/help-faq.model';
import { HelpFaqSummary } from 'app/connect/models/help-faq-summary.model';

@Injectable()
export class HelpFaqService {

    private baseUrl = 'api/v2/help-faqs';

    constructor(private httpClient: HttpClient) { }

    searchHelpFaqs$(searchQuery: string): Observable<HelpFaqSummary[]> {
        const url = `${this.baseUrl}/search`;
        return this.httpClient.get<HelpFaqSummary[]>(url, { params: this.getSearchQueryParameters(searchQuery) });
    }

    getHelpFaqs$(onlyFaqs = false): Observable<HelpFaqSummary[]> {
        return this.httpClient.get<HelpFaqSummary[]>(this.baseUrl, { params: this.getHelpFaqsParameters(onlyFaqs) });
    }

    getHelpFaq$(id: number): Observable<HelpFaq> {
        const url = `${this.baseUrl}/${id}`;
        return this.httpClient.get<HelpFaq>(url);
    }

    getShowHelpFaqsOnNavigation$(): Observable<boolean> {
        const url = `${this.baseUrl}/show-on-navigation`;
        return this.httpClient.get<boolean>(url);
    }

    private getSearchQueryParameters(searchQuery: string): HttpParams {
        let params = new HttpParams();
        params = params.append('searchQuery', searchQuery);
        return params;
    }

    private getHelpFaqsParameters(onlyFaqs: boolean): HttpParams {
        let params = new HttpParams();
        params = params.append('onlyFaqs', onlyFaqs);
        return params;
    }
}
