import { AbstractControl } from '@angular/forms';

export class PasswordValidation {

    static matchPassword(abstractControl: AbstractControl) {

       const password = abstractControl.get('password').value;
       const confirmPassword = abstractControl.get('confirmPassword').value;

        if (password !== confirmPassword) {
            abstractControl.get('confirmPassword').setErrors({ matchpassword: true });
        } else {
            abstractControl.get('confirmPassword').setErrors(null);
            return null;
        }
    }
}