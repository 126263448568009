export class ImagePopupData {
    imageUrl: string;
    title: string;
    subTitle: string;
    requiresAuthorisation: boolean;

    constructor(imageUrl: string, title: string, requiresAuthorisation: boolean = false, subTitle: string = '') {
        this.imageUrl = imageUrl;
        this.title = title;
        this.subTitle = subTitle;
        this.requiresAuthorisation = requiresAuthorisation;
    }
}