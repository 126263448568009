import { Injectable } from '@angular/core';

// ngrx | rxjs
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

// store
import * as fromConnect from 'app/connect/store';

// services
import { AlertService } from 'app/shared/components/alert/services/alert.service';
import { HelpFaqService } from 'app/connect/services/help-faq.service';

@Injectable()
export class HelpFaqEffects {
    searchHelpFaqs$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SearchHelpFaqs),
        switchMap(action => this.helpFaqService.searchHelpFaqs$(action.searchQuery).pipe(
                map(helpFaqs => fromConnect.SearchHelpFaqsSuccess({ helpFaqs })),
                catchError(() => of(fromConnect.SearchHelpFaqsFail()))
            ))
    ));

    getHelpFaqs$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetHelpFaqs),
        switchMap(action => this.helpFaqService.getHelpFaqs$(action.onlyFaqs).pipe(
                map(helpFaqs => fromConnect.GetHelpFaqsSuccess({ helpFaqs })),
                catchError(() => of(fromConnect.GetHelpFaqsFail()))
            ))
    ));

    getHelpFaq$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetHelpFaq),
        switchMap(action => this.helpFaqService.getHelpFaq$(action.id).pipe(
                map(helpFaq => fromConnect.GetHelpFaqSuccess({ helpFaq })),
                catchError(() => of(fromConnect.GetHelpFaqFail()))
            ))
    ));

    getShowHelpFaqsOnNavigation$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.GetShowHelpFaqsOnNavigation),
        switchMap(() => this.helpFaqService.getShowHelpFaqsOnNavigation$().pipe(
                map(showHelpFaqsOnNavigation => fromConnect.GetShowHelpFaqsOnNavigationSuccess({ showHelpFaqsOnNavigation })),
                catchError(() => of(fromConnect.GetShowHelpFaqsOnNavigationFail()))
            ))
    ));

    fail$ = createEffect(() => this.actions$.pipe(
        ofType(fromConnect.SearchHelpFaqsFail || fromConnect.GetHelpFaqsFail || fromConnect.GetHelpFaqFail || fromConnect.GetShowHelpFaqsOnNavigation),
        tap(() => this.alertService.error('An error occurred. Please contact support'))
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private helpFaqService: HelpFaqService,
        private alertService: AlertService) { }
}