import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ValidationMessages } from 'app/shared/models';

@Injectable()
export class ValidationService {

    private validationMessages = new ValidationMessages().validationMessages;

    hasError(c: AbstractControl): boolean {
        if (c && c.errors) {
            return true;
        }

        return false;
    }

    getError(c: AbstractControl): string {

        const formGroup = c.parent.controls;
        const controlName = Object.keys(formGroup).find(name => c === formGroup[name]) || null;

        const validationMessage = this.validationMessages.find(m => m.key.toLowerCase() === controlName.toLowerCase());

        if (!validationMessage) {
            return '';
        }

        if (c.hasError('required')) {
            return validationMessage.required;
        } else if (c.hasError('minlength')) {
            return validationMessage.minlength;
        } else if (c.hasError('maxlength')) {
            return validationMessage.maxlength;
        } else if (c.hasError('pattern') || c.hasError('email')) {
            return validationMessage.pattern;
        } else if (c.hasError('matchpassword')) {
            return 'Passwords do not match';
        }

        return '';
    }

    hasRequiredError(c: AbstractControl): boolean {
        if (c) {
            return (c.hasError('required'));
        } else {
            return false;
        }
    }

    hasValidationError(c: AbstractControl): boolean {
        if (c) {
            return (c.hasError('pattern') && !c.hasError('required'));
        } else {
            return false;
        }
    }

    getRequiredError(label: string): string {
        return `${label} is required.`;
    }

    getInvalidError(label: string, example: string): string {
        const ex = example ? ` (e.g. ${example})` : '';
        return `Please enter a valid ${label}${ex}.`;
    }

    setValidators(form: FormGroup, controlName: string, switchOn: boolean, newValidators: ValidatorFn | ValidatorFn[]): void {
        const control = form.get(controlName);
        if (switchOn) {
            control.setValidators(newValidators);
        } else {
            control.setValidators(null);
        }
        control.updateValueAndValidity();
    }

    printValidationErrors(form: FormGroup): void {
        for (const i in form.controls) {
            if (form.controls[i]) {
                console.log(i + ' ' + form.controls[i].valid);
            }
        }
    }
}
