import { HttpParams } from '@angular/common/http';

declare global {
    interface Array<T> { // eslint-disable-line @typescript-eslint/no-unused-vars
        any(): boolean;
        all(target: any[]): boolean;
        /**
         * @returns A instance of httpParams that contains the array as a query param list ?myArray=1&myArray=2 etc.
         */
        toHttpParam(httpParam: HttpParams, paramStringName: string): HttpParams;
    }
}

Array.prototype.any = function() {
    return this && this.length > 0;
};

Array.prototype.all = function(target: any[]) {
    if (!target) {
        return true;
    }

    if (!this) {
        return false;
    }

    for (const filterItem of target) {
        if (!this.includes(filterItem)) {
            return false;
        }
    }

    return true;
};


Array.prototype.toHttpParam = function(httpParam: HttpParams, paramStringName: string) {
    this.forEach(element => {
        httpParam = httpParam.append(paramStringName, element.toString());
    });

    return httpParam;
};

export { };