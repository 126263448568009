import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

// rxjs |ngrx
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// models
import { SearchableOption } from 'app/shared/models/searchable-option.model';


@Component({
    selector: 'app-searchable-input',
    templateUrl: './searchable-input.component.html',
})
export class SearchableInputComponent implements OnInit {

    @Input()
    options: SearchableOption[];

    @Input()
    currentValue: string;

    @Input()
    label: string = null;

    @Input()
    readonly: boolean = false;

    @Output()
    optionSelected = new EventEmitter<SearchableOption>();

    searchControl: FormControl;

    filteredOptions: Observable<SearchableOption[]>;
    selectedOption: SearchableOption;

    ngOnInit(): void {
        this.searchControl = new FormControl();
        this.selectedOption = this.options.find(o => o.selected);
        this.searchControl.setValue(this.selectedOption);

        if (this.readonly) {
            this.searchControl.disable();
        } else {
            this.filteredOptions = this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterOptions(value))
            );
        }
    }

    filterOptions(value: string): SearchableOption[] {
        if (value && typeof value === 'string' && this.options) {
            const filterValue = value.toLowerCase();
            return this.options.filter(option => option.value.toLowerCase().includes(filterValue));
        } else {
            return this.options;
        }
    }

    getDisplay(option: SearchableOption): string {
        return option?.value ?? '';
    }

    onOptionSelected(selectedOption: SearchableOption) {
        this.selectedOption = selectedOption;
        this.optionSelected.emit(selectedOption);
    }
}