import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// models
import { Tooltip } from 'app/connect/models/tooltip.model';

// enums
import { TooltipPortal } from 'app/connect/enums/tooltip-portal.enum';
import { TooltipPage } from 'app/connect/enums/tooltip-page.enum';

@Injectable()
export class TooltipService {
    private baseUrl = 'api/v2/tooltips';

    constructor(private httpClient: HttpClient) { }

    getTooltips$(page: TooltipPage, isTour = false): Observable<Tooltip[]> {
        return this.httpClient.get<Tooltip[]>(this.baseUrl, { params: this.getParameters(page, isTour) });
    }

    createTooltipUserRecord$(tooltip: Tooltip): Observable<any> {
        return tooltip ? this.httpClient.post('api/v2/tooltip-user-records', tooltip) : new Observable(null);
    }

    getShowTooltipsTourOnNavigation$(): Observable<boolean> {
        const url = `${this.baseUrl}/show-on-navigation`;
        return this.httpClient.get<boolean>(url);
    }

    private getParameters(page: TooltipPage, isTour = false): HttpParams {
        let httpParams = new HttpParams();

        httpParams = httpParams.append('portal', TooltipPortal.ClientPortal);
        httpParams = httpParams.append('page', page);

        if (isTour) {
            httpParams = httpParams.append('isTour', isTour);
        }

        return httpParams;
    }
}
