// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(StoreNames.connectCommon);
const reducerState = createSelector(storeState, (state: reducers.ConnectStoreState) => state.currencies);

export const getCurrencies = createSelector(
    reducerState,
    (state: reducers.CurrencyListState) => state.currencies
);

export const getCurrenciesLoading = createSelector(
    reducerState,
    (state: reducers.CurrencyListState) => state.loading
);
