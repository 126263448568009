import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum TooltipPage {
    // entity portal pages
    EntityPortalCredasLanding = 1,
    EntityPortalClientLanding = 2,

    EntityPortalCredasLandingPortal = 3,
    EntityPortalCredasLandingDocuments = 4,
    EntityPortalCredasLandingIdentityChecks = 5,
    EntityPortalCredasLandingMilestones = 6,

    EntityPortalClientLandingTasks = 7,
    EntityPortalClientLandingDocuments = 8,
    EntityPortalClientLandingIdentityChecks = 9,
    EntityPortalClientLandingMilestones = 10,

    // client portal pages
    ClientPortalPage = 11,
    ClientPortalMyAccount = 12,
    ClientPortalHelpFaqsSearch = 13,

    ClientPortalPortalInvites = 150,
    ClientPortalPortalEntities = 151,
    ClientPortalPortalUsers = 154,
    ClientPortalPortalUserGroups = 167,
    ClientPortalPortalDocuments = 174,

    ClientPortalCustomisationCentreDocumentTemplates = 158,
    ClientPortalCustomisationCentreForms = 152,
    ClientPortalCustomisationCentreJourneys = 153,
    ClientPortalCustomisationCentreInfoPages = 149,
    ClientPortalCustomisationCentreLists = 165,
    ClientPortalCustomisationCentreSmsTemplates = 173,
    ClientPortalCustomisationCentreEmailTemplates = 175,

    ClientPortalClientManagementClients = 170,

    ClientPortalBrandingEntityBranding = 161,
    ClientPortalBrandingPortalBranding = 162,
    ClientPortalBrandingEntityAdverts = 163,

    ClientPortalConnectManagementClients = 156,
    ClientPortalConnectManagementTerms = 157,
    ClientPortalConnectManagementSuperUsers = 119,
    ClientPortalConnectManagementApplications = 160,
    ClientPortalConnectManagementEndUsers = 225,

    ClientPortalInfoFeatureRequests = 178,
    ClientPortalInfoReleaseNotes = 182,
    ClientPortalInfoRoadmaps = 184,

    ClientPortalProductConfigurationSystemAds = 164,
    ClientPortalProductConfigurationHelpFaqs = 168,
    ClientPortalProductConfigurationReleaseNotes = 180,
    ClientPortalProductConfigurationRoadmaps = 183,
    ClientPortalProductConfigurationTooltips = 214,
    ClientPortalProductConfigurationEmailTemplates = 176,
}

export const TooltipPages = [
    new EnumValueTitle(TooltipPage.EntityPortalCredasLanding, 'Entity - Credas Landing'),
    new EnumValueTitle(TooltipPage.EntityPortalClientLanding, 'Entity - Client Landing'),
    new EnumValueTitle(TooltipPage.EntityPortalCredasLandingPortal, 'Entity - Credas Landing - Portal'),
    new EnumValueTitle(TooltipPage.EntityPortalCredasLandingDocuments, 'Entity - Credas Landing - Documents'),
    new EnumValueTitle(TooltipPage.EntityPortalCredasLandingIdentityChecks, 'Entity - Credas Landing - Identity Checks'),
    new EnumValueTitle(TooltipPage.EntityPortalCredasLandingMilestones, 'Entity - Credas Landing - Milestones'),
    new EnumValueTitle(TooltipPage.EntityPortalClientLandingTasks, 'Entity - Client Landing - Tasks'),
    new EnumValueTitle(TooltipPage.EntityPortalClientLandingDocuments, 'Entity - Client Landing - Documents'),
    new EnumValueTitle(TooltipPage.EntityPortalClientLandingIdentityChecks, 'Entity - Client Landing - Identity Checks'),
    new EnumValueTitle(TooltipPage.EntityPortalClientLandingMilestones, 'Entity - Client Landing - Milestones'),

    new EnumValueTitle(TooltipPage.ClientPortalPage, 'Client - Page'),
    new EnumValueTitle(TooltipPage.ClientPortalMyAccount, 'Client - Header - My Account'),
    new EnumValueTitle(TooltipPage.ClientPortalHelpFaqsSearch, 'Client - Help & Faqs Search'),
    new EnumValueTitle(TooltipPage.ClientPortalPortalInvites, 'Client - Portal - Invites'),
    new EnumValueTitle(TooltipPage.ClientPortalPortalEntities, 'Client - Portal - Entities'),
    new EnumValueTitle(TooltipPage.ClientPortalPortalUsers, 'Client - Portal - Users'),
    new EnumValueTitle(TooltipPage.ClientPortalPortalUserGroups, 'Client - Portal - User Groups'),
    new EnumValueTitle(TooltipPage.ClientPortalPortalDocuments, 'Client - Portal - Documents'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreDocumentTemplates, 'Client - Customisation Centre - Documents'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreForms, 'Client - Customisation Centre - Forms'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreJourneys, 'Client - Customisation Centre - Journeys'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreInfoPages, 'Client - Customisation Centre - Info Pages'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreLists, 'Client - Customisation Centre - Lists'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreSmsTemplates, 'Client - Customisation Centre - Sms'),
    new EnumValueTitle(TooltipPage.ClientPortalCustomisationCentreEmailTemplates, 'Client - Customisation Centre - Emails'),
    new EnumValueTitle(TooltipPage.ClientPortalClientManagementClients, 'Client - Client Management - Clients'),
    new EnumValueTitle(TooltipPage.ClientPortalBrandingEntityBranding, 'Client - Branding - Entity Branding'),
    new EnumValueTitle(TooltipPage.ClientPortalBrandingPortalBranding, 'Client - Branding - Portal Branding'),
    new EnumValueTitle(TooltipPage.ClientPortalBrandingEntityAdverts, 'Client - Branding - Entity Adverts'),
    new EnumValueTitle(TooltipPage.ClientPortalConnectManagementClients, 'Client - Connect Management - Clients'),
    new EnumValueTitle(TooltipPage.ClientPortalConnectManagementTerms, 'Client - Connect Management - Terms'),
    new EnumValueTitle(TooltipPage.ClientPortalConnectManagementSuperUsers, 'Client - Connect Management - Super Users'),
    new EnumValueTitle(TooltipPage.ClientPortalConnectManagementApplications, 'Client - Connect Management - Applications'),
    new EnumValueTitle(TooltipPage.ClientPortalConnectManagementEndUsers, 'Client - Connect Management - End Users'),
    new EnumValueTitle(TooltipPage.ClientPortalInfoFeatureRequests, 'Client - Info - Feature Requests'),
    new EnumValueTitle(TooltipPage.ClientPortalInfoReleaseNotes, 'Client - Info - Release Notes'),
    new EnumValueTitle(TooltipPage.ClientPortalInfoRoadmaps, 'Client - Info - Roadmaps'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationSystemAds, 'Client - Product Config - System Ads'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationHelpFaqs, 'Client - Product Config - Help Faqs'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationReleaseNotes, 'Client - Product Config - Release Notes'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationRoadmaps, 'Client - Product Config - Roadmaps'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationTooltips, 'Client - Product Config - Tooltips'),
    new EnumValueTitle(TooltipPage.ClientPortalProductConfigurationEmailTemplates, 'Client - Product Config - Emails'),
];

export function getTooltipPageName(tooltipPage: TooltipPage): string {
    return TooltipPages.find(d => d.value === tooltipPage)?.title;
}