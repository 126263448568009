// ngrx
import { Action, createReducer, on } from '@ngrx/store';

// actions
import * as CurrencyListActions from 'app/connect/store/actions/currency-list.actions';

// models
import { Currency } from 'app/connect/models/currency.model';

export interface CurrencyListState {    
    currencies: Currency[];
    loading: boolean;    
}

const initialState: CurrencyListState = {    
    currencies: [],
    loading: false    
};

const currencyListReducer = createReducer(
    initialState,    
    on(CurrencyListActions.GetCurrencies, (state) => ({
        ...state,
        loading: true        
    })),
    on(
        CurrencyListActions.GetCurrenciesSuccess,
        (state, { currencies }) => ({
            ...state,
            currencies,
            loading: false            
        })
    ),
    on(CurrencyListActions.GetCurrenciesFailure, (state) => ({
        ...state,
        loading: false        
    })),    
);

export function reducer(state: CurrencyListState, action: Action) {
    return currencyListReducer(state, action);
}
