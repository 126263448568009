import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/shared/base/base-component';

@Component({
    selector: 'app-card-container',
    templateUrl: './card-container.component.html',
    styleUrls: [ './card-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CardContainerComponent extends BaseComponent {

}