import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'ukdate'
})
export class UkDatePipe extends DatePipe implements PipeTransform {
    public transform(value: any, format: string): any {
        if (format === 'time') {
            format = ' HH:mm';
        } else {
            format = '';
        }

        return super.transform(value, `dd/MM/yyyy${format}` );
     }
}