import * as fromRouter from '@ngrx/router-store';

export type Action = fromRouter.RouterRequestAction;

const MAX_PREVIOUS_URLS = 3;

export interface State {
    url: string;
    previousUrls: string[];
}

const initialState: State = {
    url: null,
    previousUrls: []
};

export function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case fromRouter.ROUTER_REQUEST: {
            const previousUrls = state.previousUrls || [];
            const newPreviousUrls = [action.payload.routerState.url, ...previousUrls]
                .filter(x => !!x)
                .slice(0, MAX_PREVIOUS_URLS);

            return {
                ...state,
                url: action.payload.event.url,
                previousUrls: newPreviousUrls
            };
        }

        default:
            return state;
    }
}
