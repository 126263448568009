export class ImageMetaData {
    exifOrientation: number;
    width: number;
    height: number;

    constructor(orientation: number, width: number, height: number) {
        this.exifOrientation = orientation;
        this.height = height;
        this.width = width;
    }

    needsTransform(): boolean {
        return this.exifOrientation && this.exifOrientation !== 1;
    }
}