// ngrx
import { createFeatureSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';

// store
import * as reducers from 'app/connect/store/reducers';

// models
import { StoreNames } from 'app/connect/models/store-names.model';

const storeState = createFeatureSelector<reducers.ConnectStoreState>(StoreNames.connectCommon);
const reducerState = createSelector(storeState, (state: reducers.ConnectStoreState) => state.helpFaq);

export const getHelpFaqState = reducerState;
export const getHelpFaqs = createSelector(reducerState, (state: reducers.HelpFaqState) => state.helpFaqs);
export const getHelpFaq = createSelector(reducerState, (state: reducers.HelpFaqState) => state.helpFaq);
export const getShowHelpFaqsOnNavigation = createSelector(reducerState, (state: reducers.HelpFaqState) => state.showHelpFaqsOnNavigation);
export const getLoading = createSelector(reducerState, (state: reducers.HelpFaqState) => state.loading);
