declare global {
    interface Date {
        toQueryStringParam(): string;
        monthName(): string;
        toObservationString(): string;
    }
}

Date.prototype.toQueryStringParam = function() {
    if (!this) {
        return null;
    }

    return `${this.getDate()}/${this.monthName()}/${this.getFullYear()}`;
};

Date.prototype.monthName = function() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return months[this.getMonth()];
};

Date.prototype.toObservationString = function(): string {
    if (!this) {
        return null;
    }

    const day = this.getDate();
    const month = this.getMonth() + 1;
    const year = this.getFullYear();

    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

export { };