import { FilterOption } from './filter-option.model';

export class FilterOptionSelection {
    name: string;
    selectedIds: string[] = [];

    constructor(filterOption: FilterOption) {
        this.name = filterOption.name;
    }

    clear(): void {
        this.selectedIds = [];
    }

    isSelected(id: string): boolean {
        return this.selectedIds.includes(id);
    }

    selectValue(id: string): void {
        this.selectedIds.push(id);
    }
}