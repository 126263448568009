<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div> 
        <ng-content></ng-content>
    </div>
    <mat-icon class="pointer" (click)="showInfo = true">info</mat-icon>
    <div *ngIf="showInfo" class="info-box" (click)="showInfo = false">
        <div class="inner" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center" class="primary-bg primary-reverse-text padding-16">
                <div fxFlex>{{title}}</div>
                <mat-icon class="pointer" (click)="showInfo = false">close</mat-icon>
            </div>
            <div class="padding-16" [innerHtml]="info | safe: 'html'"></div>
        </div>
    </div>
</div>