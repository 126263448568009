import { createAction, props } from '@ngrx/store';

import { Currency } from 'app/connect/models/currency.model';

export const GetCurrencies = createAction(
    '[Currency List] Get Currencies'
);

export const GetCurrenciesSuccess = createAction(
    '[Currency List] Get Currencies Success',
    props<{ currencies: Currency[] }>()
);

export const GetCurrenciesFailure = createAction(
    '[Currency List] Get Currencies Failure'
);
