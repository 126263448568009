import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Components
import { BaseComponent } from 'app/shared/base/base-component';

// Services
import { AuthenticationV2Service } from 'app/authentication-v2/services/authentication-v2.service';

@Component({
    templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
    form: FormGroup;

    hidePassword: boolean = true;
    emailSent: boolean = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationV2Service) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    sendEmail() {
        // this.authenticationEventTrackingService.sendResetPasswordClicked();
        const email = this.form.get('email').value;
        this.authService.sendPasswordResetEmail(email).subscribe(() => {
            this.emailSent = true;
        });
    }
}