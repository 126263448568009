import { EnumValueTitle } from 'app/shared/models/enum-value-title.model';

export enum MagicLinkType {
    CredasDashboard = 1,
    ClientPortal = 2,
    Process = 3
}

export function getMagicLinkTypes(clientName: string) {
    return [
        new EnumValueTitle(MagicLinkType.CredasDashboard, 'Credas Dashboard'),
        new EnumValueTitle(MagicLinkType.ClientPortal, `${clientName} Portal`),
        new EnumValueTitle(MagicLinkType.Process, 'Process Landing Page')
    ];
}