// ngrx
import { createAction, props } from '@ngrx/store';

// models
import { HelpFaq } from 'app/connect/models/help-faq.model';
import { HelpFaqSummary } from 'app/connect/models/help-faq-summary.model';

// actions
export const SearchHelpFaqs = createAction(
    '[Connect - Help Faq Search] Search help or faqs',
    props<{searchQuery: string}>()
);

export const SearchHelpFaqsSuccess = createAction(
    '[Connect - Help Faq Search] Search help or faqs success',
    props<{helpFaqs: HelpFaqSummary[]}>()
);

export const SearchHelpFaqsFail = createAction(
    '[Connect - Help Faq Search] Search help or faqs fail',
);

export const ResetHelpFaqSearchItems = createAction(
    '[Connect - Help Faq Search] Reset help or faqs search items',
);

export const GetHelpFaqs = createAction(
    '[Connect - Help Faq] Get help or faqs',
    props<{onlyFaqs: boolean}>()
);

export const GetHelpFaqsSuccess = createAction(
    '[Connect - Help Faq] Get help or faqs success',
    props<{helpFaqs: HelpFaqSummary[]}>()
);

export const GetHelpFaqsFail = createAction(
    '[Connect - Help Faq] Get help or faqs fail',
);

export const GetHelpFaq = createAction(
    '[Connect - Help Faq] Get help or faq',
    props<{id: number}>()
);

export const GetHelpFaqSuccess = createAction(
    '[Connect - Help Faq] Get help or faq success',
    props<{helpFaq: HelpFaq}>()
);

export const GetHelpFaqFail = createAction(
    '[Connect - Help Faq] Get help or faq fail',
);

export const GetShowHelpFaqsOnNavigation = createAction(
    '[Connect - Help Faq] Get show on navigation',
);

export const GetShowHelpFaqsOnNavigationSuccess = createAction(
    '[Connect - Help Faq] Get show on navigation success',
    props<{showHelpFaqsOnNavigation: boolean}>()
);

export const GetShowHelpFaqsOnNavigationFail = createAction(
    '[Connect - Help Faq] Get show on navigation fail',
);
