import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// models
import { Branding } from 'app/models/branding.model';

@Injectable()
export class ConnectService {

    constructor(private http: HttpClient) { }

    getDefaultBranding$(): Observable<Branding> {
        return this.http.get<Branding>('api/branding');
    }

    convertShortUrl$(shortCode: string): Observable<string> {
        const url = `api/v2/links/${shortCode}`;
        return this.http.get<string>(url, { responseType: 'text' as 'json'});
    }
}