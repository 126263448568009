import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-card-display',
    templateUrl: './card-display.component.html'
})
export class CardDisplayComponent {

    @Input()
    cardTitle: string = '';

    @Output()
    cardClick = new EventEmitter();

    cardClicked() {
        this.cardClick.emit();
    }
}