import { Injectable } from '@angular/core';

@Injectable()
export class PasswordService {

    // excluding confusing characters eg oO0
    alpha = 'abcdefghjkmnpqrstuvwxyz';
    number = '23456789';
    special = '!?£$*#';

    generateTempPassword() {
        // 1 special char
        // 2 numbers
        // 2 uppercase alpha
        // 5 lowercase alpha
        let password = '';
        password += this.getCharacters(this.special, 1);
        password += this.getCharacters(this.number, 2);
        password += this.getCharacters(this.alpha, 5);
        password += this.getCharacters(this.alpha, 2).toUpperCase();
        return this.shuffle(password);
    }

    private getCharacters(charset: string, n: number): string {
        let result = '';
        for (let i = 0; i < n; i++) {
            result += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return result;
    }

    private shuffle(input: string): string {
        return  input.split('').sort(() => 0.5 - Math.random()).join('');
    }
}