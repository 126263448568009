// Enums
import { AlertType } from 'app/shared/components/alert/enums/alert-type.enum';

export class Alert {
    icon: string;
    message: string;
    type: AlertType;

    constructor(message: string, type: AlertType) {
        this.message = message;
        this.type = type;

        switch (this.type) {
            case AlertType.Info:
                this.icon = 'info';
                break;
            case AlertType.Warning:
                this.icon = 'warning';
                break;
            case AlertType.Error:
                this.icon = 'error';
                break;
            case AlertType.Success:
                this.icon = 'done';
                break;
        }
    }
}